<template>
  <div id="home-container">
    <slot/> 
  </div>
</template>

<script>
// This layout is for the home screen
export default {
  name: "homelayout"
};
</script>

<style lang="scss" scoped>
</style>